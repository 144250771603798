/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../../helpers';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useAuth } from '../../../../../app/modules/auth';
import { FileContext } from '../../../../../app/modules/accounts/Filecontext';
import { USER_ROLES } from "../../../../../helpers/utils";

const menuList = [
  { to: '/company-select', icon: '/media/menu/gen019.svg', title: 'Company Select', isSuperAdmin: true, isEditor: true },
  { to: '/analytics/*', icon: '/media/menu/bar-chart-line.svg', title: 'Results', isSuperAdmin: false, isEditor: false },
  {
    to: '/apps/chat',
    icon: '/media/menu/Enquiries.svg',
    title: 'Enquiries',
    isEditor: false,
    sub: [
      { to: '/private-chat', title: 'Chats', hasBullet: true, isSuperAdmin: false },
      // {to: "/statistics", title: "Statistics", hasBullet: true, isSuperAdmin: false},
      { to: '/statistics', title: 'Statistics', hasBullet: true, isSuperAdmin: false },
      { to: '/spam', title: 'Spam', hasBullet: true, isSuperAdmin: false },
      { to: '/customisation', title: 'Customisation', hasBullet: true, isSuperAdmin: false },
    ],
  },
  { to: '/projects', icon: '/media/menu/image-gallery.svg', title: 'Projects', isSuperAdmin: false, isEditor: false },
  { to: '/ad_channels', icon: '/media/menu/target.svg', title: 'Ad Channels', isSuperAdmin: false, isEditor: false },
  {
    to: '/profile',
    icon: '/media/menu/Profile.svg',
    title: 'Profile',
    isEditor: true,
    sub: [
      { to: '/details', title: 'Details', hasBullet: true, isSuperAdmin: false },
      { to: '/locations', title: 'Location', hasBullet: true, isSuperAdmin: false },
      { to: '/social', title: 'Social', hasBullet: true, isSuperAdmin: false },
      { to: '/description', title: 'Description', hasBullet: true, isSuperAdmin: false },
      { to: '/hours', title: 'Opening Hours', hasBullet: true, isSuperAdmin: false },
      { to: '/brands-services', title: 'Brands/Services', hasBullet: true, isSuperAdmin: false },
      { to: '/gallery', title: 'Gallery', hasBullet: true, isSuperAdmin: false },
      { to: '/youtube-form', title: 'Videos', hasBullet: true, isSuperAdmin: false },
      { to: '/settings', title: 'Admin', hasBullet: true, isSuperAdmin: true },
    ],
  },
  { to: '/settings', icon: '/media/menu/Settings.svg', title: 'Settings', isSuperAdmin: false, isEditor: false },
  { to: '/global-settings', icon: '/media/menu/Global.svg', title: 'Global Settings', isSuperAdmin: true, isEditor: false },
];

const SidebarMenuMain = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();
  const isSuperAdmin = currentUser?.isSuperAdmin || false;
  const { selectedCompany } = useContext(FileContext);
  const companyId = selectedCompany || localStorage.getItem('companyId');
  const isEditor = window.localStorage.getItem('userRole') === USER_ROLES.editor;

  // const { selectedCompany } = useContext(FileContext);
  // const companyId = selectedCompany || localStorage.getItem('companyId');
  // const { company, isLoading, refetch, isFetching } = useCompany(companyId as number);

  return (
    <>
      {(!!companyId || isSuperAdmin) &&
        menuList.map(({ sub, to: url, title, icon, isSuperAdmin: admin, isEditor: editor }) => {
          const displayUser = !admin || (admin && isSuperAdmin);

          const display = isEditor ? displayUser && editor : displayUser;

          return (
            display &&
            (sub ? (
              <SidebarMenuItemWithSub key={`${url}${title}`} to={url} title={title} icon={icon}>
                {sub.map(({ to, title, hasBullet, isSuperAdmin: subAdmin }) => {
                  const subDisplay = !subAdmin || (subAdmin && isSuperAdmin);
                  return (
                    subDisplay && (
                      <SidebarMenuItem key={`${to}${title}`} to={`${url}${to}`} title={title} hasBullet={hasBullet} />
                    )
                  );
                })}
              </SidebarMenuItemWithSub>
            ) : (
              <SidebarMenuItem key={`${url}${title}`} to={url} icon={icon} title={title} />
            ))
          );
        })}

      {process.env.REACT_APP_ENV === 'dev' && (
        <div>
          <SidebarMenuItem
            to="/dashboard"
            icon="/media/icons/duotune/art/art002.svg"
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon="bi-app-indicator"
          />
          <SidebarMenuItem
            to="/builder"
            icon="/media/icons/duotune/general/gen019.svg"
            title="Layout Builder"
            fontIcon="bi-layers"
          />
          {/*<div className='menu-item'>*/}
          {/*  <div className='menu-content pt-8 pb-2'>*/}
          {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <SidebarMenuItemWithSub
            to="/crafted/pages"
            title="Pages"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/general/gen022.svg"
          >
            <SidebarMenuItemWithSub to="/crafted/pages/profile" title="Profile" hasBullet={true}>
              <SidebarMenuItem to="/crafted/pages/profile/overview" title="Overview" hasBullet={true} />
              <SidebarMenuItem to="/crafted/pages/profile/projects" title="Projects" hasBullet={true} />
              <SidebarMenuItem to="/crafted/pages/profile/campaigns" title="Campaigns" hasBullet={true} />
              <SidebarMenuItem to="/crafted/pages/profile/documents" title="Documents" hasBullet={true} />
              <SidebarMenuItem to="/crafted/pages/profile/connections" title="Connections" hasBullet={true} />
            </SidebarMenuItemWithSub>

            <SidebarMenuItemWithSub to="/crafted/pages/wizards" title="Wizards" hasBullet={true}>
              <SidebarMenuItem to="/crafted/pages/wizards/horizontal" title="Horizontal" hasBullet={true} />
              <SidebarMenuItem to="/crafted/pages/wizards/vertical" title="Vertical" hasBullet={true} />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/error"
            title="Errors"
            fontIcon="bi-sticky"
            icon="/media/icons/duotune/general/gen040.svg"
          >
            <SidebarMenuItem to="/error/404" title="Error 404" hasBullet={true} />
            <SidebarMenuItem to="/error/500" title="Error 500" hasBullet={true} />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to="/crafted/widgets"
            title="Widgets"
            icon="/media/icons/duotune/general/gen025.svg"
            fontIcon="bi-layers"
          >
            <SidebarMenuItem to="/crafted/widgets/lists" title="Lists" hasBullet={true} />
            <SidebarMenuItem to="/crafted/widgets/statistics" title="Statistics" hasBullet={true} />
            <SidebarMenuItem to="/crafted/widgets/charts" title="Charts" hasBullet={true} />
            <SidebarMenuItem to="/crafted/widgets/mixed" title="Mixed" hasBullet={true} />
            <SidebarMenuItem to="/crafted/widgets/tables" title="Tables" hasBullet={true} />
            <SidebarMenuItem to="/crafted/widgets/feeds" title="Feeds" hasBullet={true} />
          </SidebarMenuItemWithSub>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">Apps</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to="/apps/chat"
            title="Chat"
            fontIcon="bi-chat-left"
            icon="/media/icons/duotune/communication/com012.svg"
          >
            <SidebarMenuItem to="/apps/chat/group-chat" title="Group Chart" hasBullet={true} />
            <SidebarMenuItem to="/apps/chat/drawer-chat" title="Drawer Chart" hasBullet={true} />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to="/apps/user-management/users"
            icon="/media/icons/duotune/general/gen051.svg"
            title="User management"
            fontIcon="bi-layers"
          />
          <div className="menu-item">
            <a target="_blank" className="menu-link" href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}>
              <span className="menu-icon">
                <KTSVG path="/media/icons/duotune/general/gen005.svg" className="svg-icon-2" />
              </span>
              <span className="menu-title">Changelog {process.env.REACT_APP_VERSION}</span>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export { SidebarMenuMain };
