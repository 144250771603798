import React, { ReactNode } from 'react';
import clsx from 'clsx';

type ChartBlockProps = {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  titleCenter?: boolean;
  className?: { [key: string]: string };
};
const ChartBlock = ({ children, title, subtitle, titleCenter, className }: ChartBlockProps) => (
  <div
    className={clsx('d-flex flex-column px-12 pt-14 shadow-sm rounded-3 h-100', className?.block, {
      'pb-6': !className?.block,
    })}
  >
    {title && <h4 className={clsx('fs-1 fw-bold mb-0', { 'text-center': titleCenter }, className?.title)}>{title}</h4>}
    {subtitle && <p className={clsx("fs-h4 fw-normal font-secondary ", className?.subtitle)}>{subtitle}</p>}
    {children}
  </div>
);

export default ChartBlock;
